/* Carousel.css */
.carousel-container {
    position: relative;
  }
  
  .carousel-slide {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    transition: all 0.5s ease-in-out;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .carousel-button.left {
    left: 10px;
  }
  
  .carousel-button.right {
    right: 10px;
  }
  
  .carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: lightgray;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .indicator.active {
    background: white;
  }
  